import { API, Storage } from "aws-amplify";
import { isNaughty, isMaybePeeChee, deleteFromS3 } from "../functions";
import { uploadSchema } from "../validations/uploadSchema";
import { withRouter } from "react-router-dom";
import axios from "axios";
import DropZone from "./DropZone";
import GreetingsBanner from "./GreetingsBanner";
import ImageAnalyzer from "./ImageAnalyzer";
import logo from "../images/pee-chee-logo.png";
import React, { useState } from "react";
import UploadForm from "./UploadForm";
import uuid from "uuid/v3";

const Uploader = ({ currentUser }) => {
  const [error, setError] = useState("");
  const [file, setFile] = useState("");
  const [labels, setLabels] = useState([]);

  const [localFileUrl, setLocalFileUrl] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [s3FileName, setS3FileName] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uid, setUid] = useState("");
  const [url, setUrl] = useState("");
  const [url2, setUrl2] = useState("");

  // DynamoDB doesn't allow empty attribute values, so this...
  // const removeEmptyStringElements = obj => {
  //   for (let prop in obj) {
  //     if (typeof obj[prop] === "object") {
  //       // dive deeper in
  //       removeEmptyStringElements(obj[prop]);
  //     } else if (obj[prop] === "") {
  //       // delete elements that are empty strings
  //       delete obj[prop];
  //     }
  //   }
  //   return obj;
  // };

  const handleSubmitDropZone = async acceptedFiles => {
    setLabels([]);

    const file = acceptedFiles[0];
    setFile(file);
    const fileName = file.name;
    const suffix = fileName
      .match(/\.[^.]*$/)[0]
      .slice(1)
      .toLowerCase();
    const fileNameNoSuffix = fileName.replace(/\.[^.]*$/, "");
    const ObjectUrl = URL.createObjectURL(file);
    setLocalFileUrl(ObjectUrl);
    console.log("Dropzone received file: ", file);

    //some quick and dirty validation
    try {
      if (acceptedFiles.length > 1) {
        throw new Error("One at a time, please.");
      }
      const okayFileTypes = ["jpg", "jpeg", "png"];
      if (!okayFileTypes.includes(suffix)) {
        throw new Error("Only jpg or png files, please.");
      }
    } catch (e) {
      setError(e.message);
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }

    setSpinner(true);
    try {
      const quickUid = uuid(fileNameNoSuffix, uuid.URL);
      setUid(quickUid);

      const quickS3 = `${uuid(fileNameNoSuffix, uuid.URL)}.${suffix}`;
      setS3FileName(quickS3);

      console.log("Uploading to S3...");
      setUploadProgress(5);
      await Storage.put(`${quickUid}.${suffix}`, file, {
        contentType: `image/${suffix}`,
        progressCallback(progress) {
          setUploadProgress(
            `${parseInt(100 * (progress.loaded / progress.total))}`
          );
          console.log(
            `Upload progress: ${parseInt(
              100 * (progress.loaded / progress.total)
            )}%`
          );
        }
      });
      console.log("Image uploaded!");

      // console.log("Getting S3 URL...");
      // const s3Url = await Storage.get(quickS3);
      // setUrl(s3Url);
      // setUrl2(s3Url);
      console.log("Starting Rekognition analysis...");
      const analysis = await API.post("PeeCheeArchiveApi", "/analyze", {
        body: {
          bucket: "peecheefolderartwork-dev",
          name: `public/${quickS3}`
        }
      });
      console.log("Rekognition analysis data received!", analysis.data);
      setLabels(analysis.data);
      if (isNaughty(analysis.data) || !isMaybePeeChee(analysis.data))
        deleteFromS3(quickS3);
      // console.log("Saving metadata to DynamoDB");
      // await API.put("PeeCheeArchiveApi", "/items", {
      //   body: {
      //     url: quickUid,
      //     s3FileName: quickS3,
      //     labels: removeEmptyStringElements(analysis.data)
      //   }
      // });
      // console.log("Metadata saved!");
      // setUrl("");
      // setUrl2("");
      setSpinner(false);
    } catch (e) {
      if (e.message === "Network Error")
        setError("The people hosting that link, don't allow you to share it.");
      console.log(e);
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }
    return;
  };

  const handleSubmitUrl = async e => {
    e.preventDefault();
    try {
      if (!currentUser) throw new Error("Only logged in users can upload.");
      await uploadSchema.validate({ url });
      const fileName = url.match(/[^/]+$/);
      if (!fileName) throw new Error("The URL needs to end with a file name");
      console.log(fileName[0]);
      const isGraphicFile = fileName[0].match(/\S{1,}.\b(jpg|jpeg|png)/);
      console.log(isGraphicFile);
      if (!isGraphicFile)
        throw new Error("That's not a fucking graphics file!");
    } catch (e) {
      setError(e.message);
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }
    setSpinner(true);
    setUrl2(url);
    const suffix = url.match(/\.[^.]*$/)[0].slice(1);
    try {
      await setUid(uuid(url, uuid.URL));
      await setS3FileName(`${uuid(url, uuid.URL)}.${suffix}`);
      const { data } = await axios.get(url);
      console.log("Uploading to S3...");
      await Storage.put(`${uid}.${suffix}`, data, {
        contentType: `image/${suffix}`
      });
      console.log("Image uploaded!");
      // return;
      // console.log("Saving metadata to DynamoDB");
      // await API.put("PeeCheeArchiveApi", "/items", {
      //   body: {
      //     url,
      //     s3FileName: uid
      //   }
      // });
      // console.log("Metadata saved!");
      const analysis = await API.post("PeeCheeArchiveApi", "/analyze", {
        body: {
          bucket: "peecheefolderartwork-dev",
          name: `public/${uid}.${suffix}`
        }
      });
      setLabels(analysis.data);

      //stop dirty stuff here!
      console.log(analysis.data);
      // setUrl("");
      // setUrl2("");
      setSpinner(false);
    } catch (e) {
      if (e.message === "Network Error")
        setError("The people hosting that link, don't allow you to share it.");
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }
    return;
  };
  return (
    <>
      <GreetingsBanner currentUser={currentUser} path={"Gallery"} />
      {parseInt(uploadProgress) !== 100 ? (
        <div className="d-flex container flex-column justify-content-center">
          <div className="d-block d-sm-none mb-4 text-center">
            <img
              src={logo}
              className="App-logo"
              alt="logo"
              height="128px"
              width="128px"
            />
          </div>
          <div className="d-none d-sm-block mt-5 mb-4 text-center">
            <img src={logo} className="App-logo" alt="logo" />
          </div>
          {/* <div
            className="d-block d-sm-none pee-chee-font text-center mb-5 animated"
            style={{ fontSize: "32px" }}
          >
            The Ultimate
            <br />
            Pee-Chee Art
            <br />
            Uploader
          </div> */}
          <div
            className="d-none d-sm-block pee-chee-font text-center mb-5 animated"
            style={{ fontSize: "48px" }}
          >
            The Ultimate Pee-Chee Doodle Art Uploader
          </div>

          <DropZone
            handleSubmit={handleSubmitDropZone}
            uploadProgress={uploadProgress}
          />
          <UploadForm
            error={error}
            handleSubmit={handleSubmitUrl}
            setUrl={setUrl}
            url={url}
            currentUser={currentUser}
            spinner={spinner}
          />
        </div>
      ) : (
        <ImageAnalyzer
          file={file}
          labels={labels}
          localFileUrl={localFileUrl}
          setLabels={setLabels}
          setLocalFileUrl={setLocalFileUrl}
          setUploadProgress={setUploadProgress}
          setUrl={setUrl}
          setUrl2={setUrl2}
          uid={uid}
          s3FileName={s3FileName}
          url={url2}
        />
      )
      // url2 ? <ImageAnalyzer labels={labels} url={url2} /> : null
      }
    </>
  );
};

export default withRouter(Uploader);
