// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_dynamodb_all_tables_region": "us-west-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "PeeCheeArchive-dev",
            "region": "us-west-2"
        }
    ],
    "aws_user_files_s3_bucket": "peecheefolderartwork-dev",
    "aws_user_files_s3_bucket_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:efc325fb-717d-42dc-b718-fb833fa46090",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_xkVgv6mri",
    "aws_user_pools_web_client_id": "2rlanfuognnfvhs9ccm2tg3tea",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "PeeCheeArchiveApi",
            "endpoint": "https://dn5eh2aj53.execute-api.us-west-2.amazonaws.com/dev",
            "region": "us-west-2"
        }
    ]
};


export default awsmobile;
