import React from "react";
import PropTypes from "prop-types";

const ProgressBar = props => {
  const { uploadProgress } = props;
  return (
    <div className="d-flex flex-column align-items-center justify-content-center my-3 w-100">
      <div
        className="progress w-100"
        style={{ backgroundColor: "#f4a932", height: "2px" }}
        // style={{ maxWidth: "768px", minWidth: "300px" }}
      >
        <div
          className="progress-bar"
          role="progressbar"
          aria-valuenow={uploadProgress}
          aria-valuemin="0"
          aria-valuemax="100"
          style={{
            backgroundColor: "#884121",
            width: `${uploadProgress}%`
          }}
        />
      </div>
      <div className="text-center mb-3">
        <small style={{ fontWeight: "600" }}>
          {uploadProgress ? uploadProgress + "%" : null}
        </small>
      </div>
    </div>
  );
};

ProgressBar.propTypes = {
  uploadProgress: PropTypes.number.isRequired
};

export default ProgressBar;
