import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { signInSchema } from "../validations/signInSchema";
import $ from "jquery";

const SignInModal = props => {
  // const { setUser } = props;

  const [error, setError] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [spinner, setSpinner] = useState(false);

  const handleSubmit = async () => {
    setError("");
    try {
      await signInSchema.validate({ username: userName, password });
      setSpinner(true);
      try {
        const { username } = await Auth.signIn(userName, password);
        setSpinner(false);
        setUserName("");
        setPassword("");
        $("#signIn-Modal").modal("hide");
        console.log(username);
      } catch (e) {
        setSpinner(false);
        setError(e.message);
        console.log(e);
      }
    } catch (e) {
      setSpinner(false);
      setError(e.message);
      console.log(e);
    }
  };

  useEffect(() => {
    const login = async () => {
      setError("");
      try {
        await signInSchema.validate({ username: userName, password });
        setSpinner(true);
        try {
          const { username } = await Auth.signIn(userName, password);
          setSpinner(false);
          setUserName("");
          setPassword("");
          $("#signIn-Modal").modal("hide");
          console.log(username);
        } catch (e) {
          setSpinner(false);
          setError(e.message);
          console.log(e);
        }
      } catch (e) {
        setSpinner(false);
        setError(e.message);
        console.log(e);
      }
    };
    $(document).on("keypress", e => {
      if (e.which === 13) {
        login();
      }
    });
  }, [password, userName]);

  return (
    <div
      className="modal fade"
      id="signIn-Modal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="signInModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div
            className="modal-header d-flex justify-content-center"
            style={{ borderBottom: "none" }}
          >
            <div
              className="d-flex flex-column justify-content-center align-items-center align-self-center mt-3"
              style={{
                color: "#f4a932",
                backgroundColor: "#884121",
                borderRadius: "50%",
                height: "150px",
                width: "150px"
              }}
            >
              <div
                className="modal-title pee-chee-font my-2"
                id="signInModalLabel"
                style={{ color: "#f4a932", fontSize: "32px" }}
              >
                Sign In
              </div>
              <h3
                className="barlow-font mb-0"
                style={{
                  color: "#f4a932",
                  letterSpacing: "2px",
                  fontSize: "18px"
                }}
              >
                ALL SEASON
              </h3>
              <h3
                className="barlow-font mb-1"
                style={{
                  color: "#f4a932",
                  fontSize: "18px",
                  fontWeight: "600",
                  letterSpacing: "3px"
                }}
              >
                PORTFOLIO
              </h3>
            </div>
          </div>
          <div className="modal-body">
            <form>
              <div className="form-group">
                <label htmlFor="username">Username</label>
                <input
                  autoComplete="username"
                  type="text"
                  className="form-control custom-input"
                  id="username"
                  placeholder="Slug"
                  onChange={e => setUserName(e.target.value)}
                  value={userName}
                />
              </div>
              <div className="form-group">
                <label htmlFor="inputPassword">Password</label>
                <input
                  autoComplete="current-password"
                  type="password"
                  className="form-control custom-input"
                  id="inputPassword"
                  placeholder="5ecr3t!23"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                />
                <small
                  id="formError"
                  className="form-text"
                  style={{ fontSize: "17px", fontWeight: "600" }}
                >
                  {error}
                </small>
              </div>
              <div className="d-flex justify-content-between">
                <button
                  className="btn btn-link barlow-font"
                  data-dismiss="modal"
                  style={{ color: "#884121", fontSize: "18px" }}
                >
                  cancel
                </button>
                <button
                  className="btn barlow-btn d-flex align-items-center justify-content-center"
                  style={{ width: "150px" }}
                  onClick={() => handleSubmit()}
                >
                  {spinner ? (
                    <div
                      className="spinner-border"
                      role="status"
                      style={{ color: "#884121" }}
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <span>Submit</span>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignInModal;
