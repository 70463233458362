import React, { useEffect, useState } from "react";
import { isNaughty, isMaybePeeChee } from "../functions";
import { Storage } from "aws-amplify";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
// import { labels } from "../tests/fixtures/labels";

const ImageAnalyzer = props => {
  const {
    file,
    labels,
    localFileUrl,
    s3FileName,
    setLabels,
    setLocalFileUrl,
    setUploadProgress,
    setUrl,
    setUrl2
    // uid
    // url
  } = props;

  const [aspectRatio, setAspectRatio] = useState(0);
  // const [url, setUrl] = useState("http://i.imgur.com/8v3MUwG.jpg");

  useEffect(() => {
    let img = new Image();
    img.src = localFileUrl;
    img.onload = () => {
      setAspectRatio(img.width / img.height);
    };
  }, [localFileUrl]);

  const handleNoItsNot = async () => {
    try {
      console.log("Deleting file from S3...", s3FileName);
      await Storage.remove(s3FileName);
      console.log("File deleted!");
      handleReset();
    } catch (e) {
      console.log(e);
    }
  };

  const handleReset = async () => {
    setUploadProgress(0);
    setUrl2("");
    setUrl("");
    setLabels([]);
    setLocalFileUrl("");
    URL.revokeObjectURL(file);
  };

  const renderLabels = () => {
    return labels.map(label => {
      return (
        <li
          className="d-flex container justify-content-between list-group-item"
          key={labels.indexOf(label)}
          style={{ backgroundColor: "transparent" }}
        >
          {label.Name}
          <span
            className="d-flex align-items-center badge badge-warning badge-pill"
            style={{ color: "#884121" }}
          >
            {parseInt(label.Confidence) + "%"}
          </span>
        </li>
      );
    });
  };

  const renderAnalysisResponse = () => {
    if (isNaughty(labels)) {
      return (
        <div className="d-flex container flex-column align-items-center my-4">
          <h3 className="text-warning text-center" style={{ fontSize: "36px" }}>
            You're So Naughty!
          </h3>
          <Link
            className="btn btn-outline-warning my-2"
            to={"/upload"}
            onClick={() => handleReset()}
          >
            Try Again
          </Link>
        </div>
      );
    }
    if (isMaybePeeChee(labels)) {
      return (
        <div className="d-flex container flex-column align-items-center my-4">
          <h3 className="text-warning text-center" style={{ fontSize: "36px" }}>
            This could be a Pee-Chee...
          </h3>
          <div className="d-flex justify-content-center w-100">
            <Link
              className="btn btn-success my-2 mx-2"
              to={"/upload"}
              onClick={() => handleReset()}
            >
              It Really Is!
            </Link>
            <Link
              className="btn btn-danger my-2 mx-2"
              to={"/upload"}
              onClick={() => handleNoItsNot()}
            >
              No, It's Not
            </Link>
          </div>
        </div>
      );
    } else {
      return (
        <div className="d-flex container flex-column align-items-center my-4 p-3">
          <h3 className="text-warning text-center" style={{ fontSize: "36px" }}>
            This ain't no Pee-Chee!
          </h3>
          <Link
            className="btn btn-outline-warning my-2"
            to={"/upload"}
            onClick={() => handleReset()}
          >
            Try Again
          </Link>
          {/* <div className="d-flex justify-content-center w-100">
            <Link
              className="btn btn-success my-2 mx-2"
              to={"/upload"}
              onClick={() => handleReset()}
            >
              I Swear On My Mother's Soul That It Is!
            </Link>
            <Link
              className="btn btn-danger my-2 mx-2"
              to={"/upload"}
              onClick={() => handleNoItsNot()}
            >
              No, It's Not. I am Human Scum.
            </Link>
          </div> */}
        </div>
      );
    }
  };

  return (
    <div className="d-flex flex-column justify-content-center align-items-center w-100">
      <div
        className="d-flex flex-column justify-content-center align-items-center text-light mt-3"
        style={{
          borderRadius: "8px",
          backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${localFileUrl})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "calc(100vh - 150px)",
          width: `calc(( 100vh - 150px ) * ${aspectRatio})`
        }}
      >
        {labels.length ? renderAnalysisResponse() : null}

        {labels.length > 0 ? (
          <div className="w-100">
            <ul
              className="list-group list-group-flush barlow-font text-light w-100 px-5"
              style={{ backgroundColor: "transparent" }}
            >
              {renderLabels()}
            </ul>
          </div>
        ) : (
          <div className="d-flex flex-column align-items-center justify-content-center">
            <h3 className="barlow-font text-warning mb-3">Analyzing</h3>
            <div className="spinner-border text-warning" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

ImageAnalyzer.propTypes = {
  url: PropTypes.string.isRequired
};

export default ImageAnalyzer;
