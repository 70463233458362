import { Auth } from "aws-amplify";
import { history } from "../routers/AppRouter";
import { Link } from "react-router-dom";
import $ from "jquery";
import React, { useEffect } from "react";
import SignInModal from "../components/SignInModal";

const GreetingsBanner = ({ path, currentUser }) => {
  useEffect(() => {
    if (!currentUser) {
      history.push("/");
    }
  }, [currentUser]);
  const handleClick = async () => {
    if (currentUser) {
      try {
        await Auth.signOut();
        console.log("Signed out");
      } catch (e) {
        console.log("error");
        console.log(e);
      }
    } else {
      $("#signIn-Modal").modal("show");
    }
  };
  return (
    <>
      <SignInModal />
      <div className="d-flex justify-content-between align-items-center">
        {currentUser ? (
          <Link
            className="pee-chee-font m-3"
            to={path === "Upload" ? "/upload" : "/"}
            style={{
              fontSize: "24px",
              cursor: "pointer"
            }}
          >
            {path}
          </Link>
        ) : null}
        <div className="d-flex align-items-center ml-auto">
          <span
            className="d-none d-sm-block"
            style={{ fontFamily: "'Pacifico', cursive" }}
          >
            {currentUser ? `Aloha ${currentUser}` : null}
          </span>
          <button
            className="d-block d-sm-none btn barlow-btn btn-sm m-3"
            onClick={() => handleClick()}
          >
            {currentUser ? "Sign Out" : "Sign In To Upload"}
          </button>
          <button
            className="d-none d-sm-block btn barlow-btn m-3"
            onClick={() => handleClick()}
          >
            {currentUser ? "Sign Out" : "Sign In To Upload"}
          </button>
        </div>
      </div>
    </>
  );
};

export default GreetingsBanner;
