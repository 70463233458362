import React, { useEffect, useState } from "react";
import { deleteFromS3 } from "../functions";

const Modal = ({ currentUser, url }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [aspectRatio, setAspectRatio] = useState(0);

  let s3Filename;
  if (url) {
    s3Filename = url
      .split(
        "https://peecheefolderartwork-dev.s3.us-west-2.amazonaws.com/public/"
      )[1]
      .split("?")[0];
  }

  useEffect(() => {
    if (url) {
      let img = new Image();
      img.src = url;
      img.onload = () => {
        setImageLoaded(true);
        setAspectRatio(img.height / img.width);
      };
    }
  }, [url]);

  return (
    <div
      className="modal fade"
      id="imageModal"
      role="dialog"
      style={{ cursor: "zoom-out" }}
    >
      <div
        className="d-flex justify-content-center modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div
          className="d-flex flex-column modal-content"
          style={{
            backgroundColor: "transparent",
            borderRadius: "8px",
            backgroundImage: `url(${url})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            height: "calc(100vh * 0.9)",
            maxWidth: `calc(100vh * 0.9 / ${aspectRatio})`,
            border: "none"
          }}
        >
          {currentUser === "Kimfucious" && imageLoaded ? (
            <div className="modal-footer" style={{ border: "none" }}>
              <button
                className="btn btn-danger"
                onClick={() => deleteFromS3(s3Filename)}
                data-dismiss="modal"
              >
                Delete
              </button>
              <i
                className="material-icons text-light"
                onClick={() => setImageLoaded(false)}
                data-dismiss="modal"
                style={{
                  fontSize: "32px",
                  textShadow:
                    "-1px -1px 0 gray, 1px -1px 0 gray, -1px 1px 0 gray, 1px 1px 0 gray"
                }}
              >
                clear
              </i>
            </div>
          ) : imageLoaded ? (
            <div className="modal-footer" style={{ border: "none" }}>
              <i
                className="material-icons text-light"
                onClick={() => setImageLoaded(false)}
                data-dismiss="modal"
                style={{
                  fontSize: "32px",
                  textShadow:
                    "-1px -1px 0 gray, 1px -1px 0 gray, -1px 1px 0 gray, 1px 1px 0 gray"
                }}
              >
                clear
              </i>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Modal;
