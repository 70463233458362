import { Router, Route, Switch } from "react-router-dom";
import { Auth, Hub } from "aws-amplify";
import App from "../App";
import Gallery from "../components/Gallery";
import { createBrowserHistory as createHistory } from "history";
import NotFoundPage from "../components/NotFoundPage";
import React, { useEffect, useState } from "react";
import Uploader from "../components/Uploader";

export const history = createHistory();

const AppRouter = () => {
  const [authState, setAuthState] = useState(false);
  const [currentUser, setCurrentUser] = useState(false);

  const listener = data => {
    setAuthState(data.payload.event);
  };
  Hub.listen("auth", listener);

  useEffect(() => {
    const getUser = async () => {
      try {
        const { username } = await Auth.currentAuthenticatedUser();
        setCurrentUser(username);
      } catch (e) {
        setCurrentUser("");
        console.log(e);
      }
    };
    getUser();
  }, [authState]);

  return (
    <Router history={history}>
      <Switch>
        <Route
          exact
          path="/"
          render={props => <App {...props} currentUser={currentUser} />}
        />
        <Route path="/gallery" component={Gallery} />
        <Route
          path="/upload"
          render={props => <Uploader {...props} currentUser={currentUser} />}
        />
        {/* <Route path="/upload" component={Uploader} /> */}
        <Route component={NotFoundPage} />
      </Switch>
    </Router>
  );
};

export default AppRouter;
