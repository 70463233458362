import React, { useEffect, useState } from "react";
import { Storage } from "aws-amplify";
import logo from "../images/pee-chee-logo.png";
import Slider from "react-slick";
import ImageModal from "./ImageModal";
import ImageModalSmall from "./ImageModalSmall";
import GreetingsBanner from "./GreetingsBanner";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "animate.css";

const Gallery = ({ currentUser }) => {
  const [urls, setUrls] = useState([]);
  const [url, setUrl] = useState("");
  const [spinner, setSpinner] = useState(false);

  useEffect(() => {
    const getUrls = async () => {
      const getUrl = key => {
        try {
          return Storage.get(key);
        } catch (e) {
          console.log(e);
        }
      };
      setSpinner(true);
      try {
        const response = await Storage.list("", {
          level: "public"
        });
        setSpinner(false);
        console.log("Getting URLs...");
        const pendingUrl = response.map(item => getUrl(item.key));
        console.log("Awaiting URL promises...");
        const promises = await Promise.all(pendingUrl);
        console.log("Promises fulfilled!", promises);
        setUrls(promises);
      } catch (e) {
        console.log(e);
      }
    };
    getUrls();
  }, []);

  const renderUrls = () => {
    return urls.map(url => {
      const index = urls.indexOf(url);
      return (
        <div key={index}>
          <div
            className="d-block d-sm-none"
            // key={index}
            title="Move mouse away to let it scroll..."
          >
            <img
              src={url}
              alt="Pee-Chee Artwork"
              style={{
                height: "calc(50vh - 100px)",
                width: "auto",
                margin: "0 auto",
                cursor: "zoom-in",
                borderRadius: "8px"
              }}
              onClick={() => setUrl(url)}
              data-toggle="modal"
              data-target="#imageModalSmall"
            />
          </div>
          <div
            className="d-none d-sm-block"
            // key={index}
            title="Move mouse away to let it scroll..."
          >
            <img
              src={url}
              alt="Pee-Chee Artwork"
              style={{
                height: "calc(50vh - 100px)",
                width: "auto",
                margin: "0 auto",
                cursor: "zoom-in",
                borderRadius: "8px"
              }}
              onClick={() => setUrl(url)}
              data-toggle="modal"
              data-target="#imageModal"
            />
          </div>
        </div>
      );
    });
  };

  const settings = {
    autoplay: true,
    autoplaySpeed: 0,
    className: "center",
    centerMode: true,
    centerPadding: "20px",
    dots: false,
    arrows: false,
    // prevArrow: <NavButton to="prev" />,
    // nextArrow: <NavButton to="next" />,
    fade: false,
    infinite: true,
    speed: 5000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerPadding: "60px"
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "20px"
        }
      }
    ]
  };
  return (
    <div className="d-flex flex-column w-100 vh-100">
      <ImageModal url={url} currentUser={currentUser} />
      <ImageModalSmall url={url} currentUser={currentUser} />
      <GreetingsBanner currentUser={currentUser} path={"Upload"} />
      <div className="d-flex flex-column justify-content-center container">
        {spinner ? (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ color: "#884121" }}
          >
            <div className="d-flex flex-column justify-content-center vh-100">
              <div
                className="d-block d-sm-none pee-chee-font text-center mb-5 animated infinite pulse justify-self-center"
                style={{ fontSize: "36px" }}
              >
                Loading...
              </div>
              <div
                className="d-none d-sm-block pee-chee-font text-center mb-5 animated infinite pulse"
                style={{ fontSize: "48px" }}
              >
                Loading...
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div
              className="d-flex flex-column d-sm-none animated fadeInUpBig mt-5"
              style={{ animationDuration: "1.5s" }}
            >
              <div className="d-block d-sm-none mb-5 text-center">
                <img
                  src={logo}
                  className="App-logo"
                  alt="logo"
                  height="128px"
                  width="128px"
                />
              </div>
              <div
                className="d-none d-sm-block pee-chee-font text-center mb-5"
                style={{ fontSize: "48px" }}
              >
                The Glorious Pee-Chee Doodle Art Gallery
              </div>
              <Slider className="animated fadeIn" {...settings}>
                {renderUrls()}
              </Slider>
            </div>
            <div
              className="d-none d-sm-flex flex-column animated fadeInUpBig"
              style={{ animationDuration: "1.5s" }}
            >
              <div className="d-none d-sm-block mb-4 text-center justify-self-start">
                <img src={logo} className="App-logo" alt="logo" />
              </div>
              <div
                className="d-none d-sm-block pee-chee-font text-center mb-5 animated"
                style={{ fontSize: "48px" }}
              >
                The Glorious Pee-Chee Doodle Art Gallery
              </div>
              <Slider className="animated fadeIn" {...settings}>
                {renderUrls()}
              </Slider>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Gallery;
