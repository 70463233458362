import React from "react";
import { Link } from "react-router-dom";

const NotFoundPage = () => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center text-center w-100 vh-100">
      <div className="display-4 mb-5">Oh, nos! There is nothing here.</div>
      <Link className="pee-chee-font" to={"/"} style={{ fontSize: "32px" }}>
        Take me home!
      </Link>
    </div>
  );
};

export default NotFoundPage;
