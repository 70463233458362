import { Storage } from "aws-amplify";

export const deleteFromS3 = async fileName => {
  try {
    console.log("Deleting file from S3...", fileName);
    await Storage.remove(fileName);
    console.log("File deleted!", fileName);
  } catch (e) {
    console.log(e);
  }
};
