import React from "react";
import "./App.scss";
import awsconfig from "./aws-exports";
import Amplify from "aws-amplify";
// import GreetingsBanner from "./components/GreetingsBanner";
import Gallery from "./components/Gallery";

Amplify.configure(awsconfig);
function App(props) {
  const { currentUser } = props;

  return (
    <>
      {/* <GreetingsBanner currentUser={currentUser} path={"Upload"} /> */}
      <div
        className="d-flex flex-column justify-content-center w-100 vh-100"
        style={{ backgroundColor: "#f4a932" }}
      >
        {/* <div className="d-none d-sm-block"> */}
        <Gallery currentUser={currentUser} />
        {/* </div> */}
        {/* <div className="d-block d-sm-none text-center">
          The gallery is terrible on mobile, right now.
        </div> */}
      </div>
    </>
  );
}

export default App;
