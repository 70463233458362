import React from "react";

const UploadForm = props => {
  const { error, handleSubmit, setUrl, spinner, url, currentUser } = props;
  return (
    <div>
      <div className="pee-chee-font text-left" style={{ fontSize: "32px" }}>
        ... or upload with a url
      </div>
      <form onSubmit={e => handleSubmit(e)}>
        <div className="form-group">
          <label htmlFor="input-url" style={{ fontSize: "24px" }}>
            Pee-Chee Folder Image URL
          </label>
          <input
            autoComplete="off"
            type="text"
            className="form-control custom-input"
            id="input-url"
            aria-describedby="url-help"
            placeholder="http://somewebsite.com/some-awesome-pee-chee-art.jpg"
            onChange={e => setUrl(e.target.value)}
            value={url}
          />
          <small
            id="url-help"
            className="form-text"
            style={{ fontSize: "17px" }}
          >
            {error}
          </small>
        </div>
        <button
          type="submit"
          className="d-flex align-items-center btn barlow-btn mb-3"
          disabled={!url || error || !currentUser === "Kimfucious"}
        >
          {spinner ? (
            <div
              className="spinner-grow spinner-grow-sm"
              role="status"
              style={{ color: "#884121" }}
            >
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <i className="material-icons">add_circle_outline</i>
          )}
          <span className="ml-2">Add to Gallery</span>
        </button>
      </form>
    </div>
  );
};

export default UploadForm;
