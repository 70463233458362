export const getCategories = labels => {
  return labels.map(label => label.Name);
};

export const isNaughty = labels => {
  const categories = getCategories(labels);
  if (
    categories.includes("Suggestive") ||
    categories.includes("Explicit Nudity") ||
    categories.includes("Female Swimware Or Underwear") ||
    categories.includes("Graphic Female Nudity") ||
    categories.includes("Graphic Male Nudity") ||
    categories.includes("Lingerie") ||
    categories.includes("Nudity") ||
    categories.includes("Revealing Clothes") ||
    categories.includes("Underwear")
  )
    return true;
  return false;
};

export const isMaybePeeChee = labels => {
  const categories = getCategories(labels);
  if (
    categories.includes("Advertisement") &&
    categories.includes("Art") &&
    categories.includes("Leisure Activities") &&
    categories.includes("Paper") &&
    categories.includes("Poster") &&
    categories.includes("Text")
  )
    return true;
  if (
    categories.includes("Advertisement") &&
    categories.includes("Brochure") &&
    categories.includes("Flyer") &&
    categories.includes("Paper") &&
    categories.includes("Poster")
  )
    return true;
  if (
    categories.includes("Advertisement") &&
    categories.includes("Art") &&
    categories.includes("Brochure") &&
    categories.includes("Collage") &&
    categories.includes("Doodle") &&
    categories.includes("Drawing") &&
    categories.includes("Flyer") &&
    categories.includes("Paper") &&
    categories.includes("Poster")
  )
    return true;
  if (
    categories.includes("Advertisement") &&
    categories.includes("Art") &&
    categories.includes("Collage") &&
    categories.includes("Doodle") &&
    categories.includes("Drawing") &&
    categories.includes("Poster")
  )
    return true;
  if (
    categories.includes("Advertisement") &&
    categories.includes("Art") &&
    categories.includes("Doodle") &&
    categories.includes("Drawing") &&
    categories.includes("Poster") &&
    categories.includes("text")
  )
    return true;
  if (
    categories.includes("Art") &&
    categories.includes("Book") &&
    categories.includes("Rug") &&
    categories.includes("Text")
  )
    return true;
  if (
    categories.includes("Advertisement") &&
    categories.includes("Human") &&
    categories.includes("Person") &&
    categories.includes("Poster")
  )
    return true;
  if (
    categories.includes("Art") &&
    categories.includes("Human") &&
    categories.includes("Person") &&
    categories.includes("Rug")
  )
    return true;
  if (
    categories.includes("Art") &&
    categories.includes("Doodle") &&
    categories.includes("Drawing")
  )
    return true;
  if (
    categories.includes("Art") &&
    categories.includes("Drawing") &&
    categories.includes("Sketch")
  )
    return true;
  if (
    categories.includes("Advertisement") &&
    categories.includes("Poster") &&
    categories.includes("Comics")
  )
    return true;
  if (
    categories.includes("Advertisement") &&
    categories.includes("Poster") &&
    categories.includes("Paper")
  )
    return true;
  if (categories.includes("Text") && categories.includes("Art")) return true;
  if (categories.includes("Art") && categories.includes("Leisure Activities"))
    return true;
  if (categories.includes("Art")) return true;
  return false;
};
