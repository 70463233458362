import React from "react";
import Dropzone from "react-dropzone";
import ProgressBar from "./ProgressBar";

const DropZone = props => {
  const { handleSubmit, uploadProgress } = props;
  return (
    <Dropzone
      onDrop={acceptedFiles => {
        handleSubmit(acceptedFiles);
      }}
    >
      {({ getRootProps, getInputProps }) => (
        <section>
          <div style={{ outline: "none" }} {...getRootProps()}>
            <input {...getInputProps()} />
            <div
              className="d-flex justify-content-center barlow-font text-center p-3"
              style={{
                border: "2px dashed #884121",
                fontSize: "32px",
                borderRadius: "10px"
              }}
            >
              Drop Pee-Chee Art Here
            </div>
            <ProgressBar uploadProgress={parseInt(uploadProgress)} />
          </div>
        </section>
      )}
    </Dropzone>
  );
};

export default DropZone;
